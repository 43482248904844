 <template>
	<el-dialog title="后台用户重置密码" top="1vh" width="600px" :visible.sync="is_show_in_page">
		<el-form label-width='80px'>
			<el-form-item label="用户姓名" >
				<el-input class="el_input" v-model="user_name" disabled></el-input>
			</el-form-item>
			<el-form-item label="用户手机" >
				<el-input class="el_input" v-model="user_tel" disabled></el-input>
			</el-form-item>
			<el-form-item label="用户组" >
				<el-input class="el_input" v-model="ug_name" disabled></el-input>
			</el-form-item>
			<el-form-item label="新密码" >
				<el-input class="el_input" v-model="new_pwd_1" type="password" :show-password="true" clearable></el-input>
			</el-form-item>
			<el-form-item label="确认密码" >
				<el-input class="el_input" v-model="new_pwd_2" type="password" :show-password="true" clearable></el-input>
			</el-form-item>
			<el-form-item >
				<el-button type="primary" @click="sub">提交</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	export default {
		props:{
			user_info:Object,
			is_show:Number,
		},
		data() {
			return {
				
				//是否显示
				is_show_in_page:false,

				//用户信息
				user_name:'',
				user_tel:'',
				ug_name:'',

				//新密码
				new_pwd_1:'',
				new_pwd_2:'',
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//打开弹出层
					this.is_show_in_page=true;
					
					//初始化
					this.init();

				}else{

					//关闭弹出层
					this.is_show_in_page=false;
				}
			}
		},
		methods: {

			//初始化
			init(){
				this.user_name=this.user_info.name
				this.user_tel=this.user_info.tel
				this.ug_name=this.user_info.ug_info.name
				this.new_pwd_1=''
				this.new_pwd_2=''
			},

			//提交
			sub(){

				if(!this.$my.check.is_login_pwd(this.new_pwd_1)){
					this.$my.other.msg({
						type:'warning',
						str:'密码需包含字母，符号或者数字中至少两项且长度超过6位数，最多不超过16位数',
					})
					return;
				}
				if(!this.new_pwd_2){
					this.$my.other.msg({
						type:'warning',
						str:'请再次输入登陆密码',
					})
					return;
				}
				if(this.new_pwd_1!==this.new_pwd_2){
					this.$my.other.msg({
						type:'warning',
						str:'两次输入不一致',
					})
					return;
				}

				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'reset_login_pwd_by_admin',
						admin_user_num:this.user_info.admin_user_num,
						login_pwd:this.new_pwd_1
					},
					callback:(res)=>{
						this.$my.other.msg({
							type:'success',
							str:'重置成功',
						})

						//关闭弹出层
						this.is_show_in_page=false;

						//通知
						this.$emit("reset");
					}
				});
			}
		}
	}
</script>
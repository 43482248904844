 <template>
	<el-dialog title="添加后台用户" top="1vh" width="60%" :visible.sync="is_show_in_page">
		
		<!-- 表单 -->
		<el-form class="form" label-width="80px">
			<div class="big_tit" style="margin:0">基本信息</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="用户姓名" style="width:30%">
					<el-input class="el_inner_width" v-model="form.name" placeholder="必填" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="用户手机" style="width:30%">
					<el-input class="el_inner_width" v-model="form.tel" placeholder="必填" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="用户组" style="width:40%">
					<el-select class="el_inner_width" v-model="form.ug_id" clearable>
						<el-option v-for="(item,index) in ug_list" :key="index" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
			</div>

			<div class="big_tit">额外权限(此处优先级高于用户组)</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label-width="0" style="width:100%">
					<div class="menu_list" v-if="is_show_in_page">
						<div v-for="(main_item,index) in menu_list" :key="index">
							<div>{{main_item.name}}</div>
							<div class="children_list">
								<div class="children_item" v-for="(children_item,index) in main_item.children" :key="index">
									<el-checkbox @change="menu_selected(children_item)">{{children_item.name}}</el-checkbox>
									<div style="margin-left:20px" v-if="children_item.controlled_btns_arr.length>0">
										<span>权限按钮:&nbsp;&nbsp;</span>
										<el-checkbox 
											v-for="(btn_item,index) in children_item.controlled_btns_arr" 
											@change="controlled_btns_selected(children_item,btn_item)" 
											:key="index" 
											:disabled="menu_and_btns[children_item.id]?(!menu_and_btns[children_item.id].checked):false" 
											:checked="menu_and_btns[children_item.id]?(menu_and_btns[children_item.id].btns[btn_item]):false" 
										>{{btn_item}}</el-checkbox>
									</div>
									<div style="margin-left:20px" v-else>权限按钮:无</div>
								</div>
							</div>
						</div>
					</div>
				</el-form-item>
			</div>
		</el-form>

		<div class="bottom_btns">
			<el-button type="primary" @click="sub">确定添加</el-button>
			<el-button type="danger" @click="clear">清空数据</el-button>
		</div>

	</el-dialog>
</template>

<script>
	export default {
		props:{
			ug_list:Array,
			menu_list:Array,
			is_show:Number,
		},
		data() {
			return {
				
				//是否显示
				is_show_in_page:false,

				//表单
				form:{
					name:'',
					tel:'',
					ug_id:'',
				},

				//可用菜单按钮
				menu_and_btns:{},
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//打开弹出层
					this.is_show_in_page=true;
					
					//初始化
					this.init();

				}else{

					//关闭弹出层
					this.is_show_in_page=false;
				}
			}
		},
		methods: {

			menu_selected(children_item){
				if(this.menu_and_btns[children_item.id].checked){
					for(var btn_item in this.menu_and_btns[children_item.id].btns){
						this.$set(this.menu_and_btns[children_item.id].btns,btn_item,false)
					}
					this.menu_and_btns[children_item.id].checked=false
					
				}else{
					this.menu_and_btns[children_item.id].checked=true
				}
			},
			controlled_btns_selected(children_item,btn_item){
				if(this.menu_and_btns[children_item.id].btns[btn_item]){
					this.$set(this.menu_and_btns[children_item.id].btns,btn_item,false)
				}else{
					this.$set(this.menu_and_btns[children_item.id].btns,btn_item,true)
				}
			},

			//提交
			sub(){

				if(!this.form.name.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"菜单名称为空,请输入"
					});
					return;
				}

				if(!this.$my.check.is_tel(this.form.tel)){
					this.$my.other.msg({
						type:'warning',
						str:"用户手机格式有误,请检查"
					});
					return;
				}

				if(!this.$my.check.is_num(this.form.ug_id)){
					this.$my.other.msg({
						type:'warning',
						str:"用户组为空,请选择"
					});
					return;
				}

				//权限按钮
				let menu_and_btns={};
				for(var menu_id in this.menu_and_btns){
					let menu_item=this.menu_and_btns[menu_id]
					if(!menu_item.checked){
						continue
					}
					menu_and_btns[menu_id]=[]
					for(var btn in menu_item.btns){
						if(menu_item.btns[btn]){
							menu_and_btns[menu_id].push(btn)
						}
					}
				}

				//调用接口
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'user_add_by_admin',
						menu_and_btns:menu_and_btns,
						...this.form
					},
					callback:(data)=>{

						//报告结果
						this.$my.other.msg({
							type:'success',
							str:"添加成功"
						});

						//关闭弹出层
						this.is_show_in_page=false;

						//通知
						this.$emit("added");
					}
				});
			},

			//初始化
			init(){

				//基础数据
				this.form.name=''
				this.form.tel=''
				this.form.ug_id=''

				//菜单
				for(var main_item of this.menu_list){
					
					for(var children_item of main_item.children){
						
						let btns={}
						for(var btn_item of children_item.controlled_btns_arr){
							btns[btn_item]=false
						}

						this.$set(this.menu_and_btns,children_item.id,{
							checked:false,
							btns:btns,
						});
					}
				}
			},

			//清空本页数据
			clear(){

				this.$my.other.confirm({
					content:"点击确定清空本页数据",
					confirm:()=>{

						//初始化
						this.init();
					}
				});
			},
		}
	}
</script>
<style lang="scss" scoped>

	//修改/添加表单的额外权限菜单部分
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		width: 90%;
		.children_list{
			margin-left: 20px;
			.children_item{
				display:flex;
				flex-wrap:wrap;
			}
		}
	}

	.el_form_item{
		margin: 0;
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
		position:relative;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}
	.el_inner_div{
		cursor: pointer;
	}

	.bottom_btns{
		text-align: center;
		position: absolute;
		bottom: 0px;
		width: calc(100% - 60px);
		padding: 15px 0;
		background-color: #fff;
		border-top: 1px solid #ccc;
	}

	.form{
		height: calc(100% - 100px);
		overflow-y:auto;
		padding-bottom:75px;
		margin-top:-20px;
	}

	.tab_height{
		height:300px;
	}

	.page{
		position:relative;
		background-color: #fff;
		border-radius: 10px;
		height: 100%;
		width: 1040px;
		overflow: auto;
		margin: auto;
	}

</style>
 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="80px">
			<el-form-item class="el_form_item" label="用户姓名">
				<el-input class="el_input" v-model="form.name" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="用户手机">
				<el-input class="el_input" v-model="form.tel" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="用户状态">
				<el-select class="el_input" v-model="form.status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="正常" value="1"></el-option>
					<el-option label="停用" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="用户组">
				<el-select class="el_input" v-model="form.ug_id" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option v-for="(item,index) in ug_list" :key="index" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="success" @click="add_view_open">添加</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column width="220" label="添加时间" prop="create_time_text"></el-table-column>
				<el-table-column width="220" label="修改时间" prop="update_time_text"></el-table-column>
				<el-table-column width="220" label="电话号码" prop="tel"></el-table-column>
				<el-table-column label="员工姓名" prop="name"></el-table-column>
				<el-table-column label="用户组">
					<template slot-scope="scope">
						<div>{{scope.row.ug_info.name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="状态" prop="status_text" width="80"></el-table-column>
				
				<!-- 操作行 -->
				<el-table-column label="操作" width="200">
					<template slot-scope="scope">
						<el-button @click="edit_view_open(scope.row)" size="mini" type="text">修改</el-button>
						<el-button v-if="scope.row.status==1" @click="status_change(scope.row,2)" size="mini" type="text">停用</el-button>
						<el-button v-if="scope.row.status==2" @click="status_change(scope.row,1)" size="mini" type="text">启用</el-button>
						<el-button @click="login_pwd_reset_view_open(scope.row)" size="mini" type="text">重置密码</el-button>
						<el-button @click="del(scope.row)" size="mini" type="text">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change" 
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]" 
			:page-size="page.num" 
			:total="list.total*1" 
			layout="total, sizes,prev, pager, next, jumper" 
		></el-pagination>

		<!-- 添加弹出层 -->
		<add 
			:ug_list="ug_list" 
			:menu_list="menu_list" 
			:is_show="add.is_show" 
			@added="add_view_close"
		></add>

		<!-- 修改弹出层 -->
		<edit 
			:ug_list="ug_list" 
			:menu_list="menu_list" 
			:data="edit.data" 
			:is_show="edit.is_show" 
			@edited="edit_view_close" 
		></edit>

		<!-- 修改密码弹出层 -->
		<loginPwdReset 
			:user_info="login_pwd_reset.user_info" 
			:is_show="login_pwd_reset.is_show" 
			@reset="edit_view_close" 
		></loginPwdReset>

	</div>
</template>

<script>
	import add from './add.vue'
	import edit from './edit.vue'
	import loginPwdReset from './login_pwd_reset.vue'
	export default {
		components:{
			add,
			edit,
			loginPwdReset
		},
		data() {
			return {

				//搜索条件
				form: {
					name:'',
					tel:'',
					status:'',
					ug_id:'',
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//其他参数
				ug_list:[],//用户组列表
				menu_list:[],//菜单列表

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//添加弹窗
				add:{
					is_show:0,
				},

				//修改弹窗
				edit:{
					data:{},
					is_show:0,
				},
				
				//重置密码弹窗
				login_pwd_reset:{
					user_info:{},
					is_show:0,
				},
			}
		},
		created() {

			//获取用户组
			this.get_ug_list()

			//获取菜单
			this.get_menu_list()
			
			//读取用户组数据
			this.get_page_data()
		},
		methods: {

			//状态变更
			status_change(item,new_status){

				//提示内容
				switch(new_status){
					case 1:var confirm_content="点击确定启用此项";break;
					case 2:var confirm_content="点击确定停用此项";break;
				}

				//询问
				this.$my.other.confirm({
					content:confirm_content,
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'admin',
								ctr:'user_edit_by_admin',
								admin_user_num:item.admin_user_num,
								status:new_status
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.get_page_data()
							},
						});
					}
				});
			},

			//删除用户组
			del(item){

				//询问
				this.$my.other.confirm({
					content:"是否删除此项",
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'admin',
								ctr:'user_del_by_admin',
								admin_user_num:item.admin_user_num
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.get_page_data()
							},
						});
					}
				});
			},

			//添加页面
			add_view_open(){
				this.add.is_show++
			},
			add_view_close(){
				this.add.is_show=0
				this.get_page_data()
			},

			//打开用户组修改页面
			edit_view_open(item){
				this.edit.data=item
				this.edit.is_show++
			},
			edit_view_close(item){
				this.edit.is_show=0
				this.get_page_data()
			},
			
			//密码重置页面
			login_pwd_reset_view_open(item){
				this.login_pwd_reset.user_info=item
				this.login_pwd_reset.is_show++
			},
			login_pwd_reset_view_close(){
				this.login_pwd_reset.is_show=0
				this.get_page_data()
			},

			//搜索
			page_ser(){
	
				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//读取用户组数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'user_list_by_admin',
						is_get_user_group:1,
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//创建时间
							item.create_time_text=this.$my.other.totime(item.create_time);

							//上次修改时间
							item.update_time_text=this.$my.other.totime(item.update_time);

							//状态
							switch(item.status){
								case '1':item.status_text="正常";break;
								case '2':item.status_text="停用";break;
							}

							//额外权限->可用菜单按钮
							item.menu_and_btns_arr=JSON.parse(item.menu_and_btns)
						}

						//渲染
						this.list.data=data.list
					}
				})
			},

			//读取菜单列表
			get_menu_list(){
				
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'menu_list_by_admin',
						status:1,
						p:1,
						num:1000
					},
					callback:(data)=>{

						//格式化主菜单
						let menu_buf={}
						let menu_main=[];
						let menu_children={};
						for(var item of data.list){

							item.controlled_btns_arr=item.controlled_btns.split(',')
							for(var i in item.controlled_btns_arr){
								if(!item.controlled_btns_arr[i]){
									item.controlled_btns_arr.splice(i,1);
								}
							}

							menu_buf[item.id]={...item};

							if(item.fid==0){
								menu_main.push(item)
							}else{
								if(!menu_children[item.fid])menu_children[item.fid]=[];
								menu_children[item.fid].push(item)
							}
						}

						//格式化子菜单
						for(var i in menu_main){
							menu_main[i].children=menu_children[menu_main[i].id]
						}

						//置入
						this.menu_list=menu_main
					}
				})
			},

			//获取用户组
			get_ug_list(){

				//尝试从缓存中取出
				let ug_list=this.$my.cache.get('__admin_user_ug_list');

				//如果取出成功
				if(ug_list){
					this.ug_list=ug_list
					return
				}

				//如果取出失败,去服务器读取
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'ug_list_by_admin',
						p:1,
						num:1000
					},
					callback:(data)=>{

						//缓存之
						this.$my.cache.set({
							__admin_user_ug_list:[...data.list]
						});

						//置入
						this.ug_list=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//修改/添加表单的额外权限菜单部分
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		width: 90%;
		.children_list{
			margin-left: 20px;
		}
	}

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 90px);
	}
	.el_form_item{
		width: 16.6672%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>